import React from "react"
import { useTitle } from "../../hooks";

const Settings: React.FC = () => {

    useTitle('设置')

    return (
        <>
        <h3>系统设置</h3>
        <div>开发中...  by wan</div>
        </>
    )
}

export default Settings;
